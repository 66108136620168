import axios from 'axios';
import Cookies from 'js-cookie';
import { auth } from './auth';

const API_ENDPOINT = 'https://api.digipromotie.nl/v1/e/collect';
const CID = process.env.GATSBY_COL_CONFIGURATION_ID;

interface UserParams {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
}

declare global {
    interface Window {
        Cookiebot: {
            consent: {
                marketing: boolean;
            };
        },
        gtag:any,
        fbq:any,
        ttq: any
    }
}

const fbPixelId = process.env.GATSBY_FB_PIXEL_ID
const ttPixelId = process.env.GATSBY_TT_PIXEL_ID

export const metaPixel = ({ eventType, eventId }:any) => {

    if (typeof window === 'undefined') return;

    if (!window.fbq) {
        const pixelScript = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq.disablePushState = true
            fbq.allowDuplicatePageViews = true
            fbq('init', '${fbPixelId}');
        `;

        const scriptTag = document.createElement('script');
        scriptTag.innerHTML = pixelScript;
        document.head.appendChild(scriptTag);
    }
    
    if (window.fbq) {
        window.fbq('track', `${eventType}`, {}, {eventID: `${eventId}`});
    }
};

export const tiktokPixel = ({ eventType, eventId }: any) => {
    if (typeof window === 'undefined') return;

    if (!window.ttq) {
        const pixelScript = `
            !function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
                var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
                ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                
                ttq.load('${ttPixelId}', { historyObserver: false });
            }(window, document, 'ttq');
        `;

        const scriptTag = document.createElement('script');
        scriptTag.innerHTML = pixelScript;
        document.head.appendChild(scriptTag);
    }

    // Wait for the TikTok library to load before firing the event
    if (window.ttq) {
        if (eventType === 'PageView') {
            window.ttq.page();
        } else {
            window.ttq.track(eventType, { event_id: eventId });
        }
    }
};


export const trackEvent = async ({ eventType, userParams }: Props) => {

    const eventTypeMap = {
        'page_view': {
            facebook: 'PageView',
            tiktok: 'PageView'
        },
        'lead': {
            facebook: 'Lead',
            tiktok: 'Lead'
        },
        'contact': {
            facebook: 'Contact',
            tiktok: 'Contact'
        },
        'schedule': {
            facebook: 'Schedule',
            tiktok: 'Schedule'
        }
    }

    const googleAdsEventMap = {
        'page_view': 'y1gACP-_iusZEK2X8rA-',
        'lead': 'y1gACP-_iusZEK2X8rA-',
        'modal_opened': 'eqJDCLy0zPEZEK2X8rA-'
    }

    const EID = crypto.randomUUID();
    const UID = Cookies.get('uid');
    const FBP = Cookies.get('_fbp');

    if (typeof window !== 'undefined' && window.Cookiebot.consent.marketing) {    
        metaPixel({
            eventId: EID,
            eventType: eventTypeMap[eventType].facebook
        })

        tiktokPixel({
            eventId: EID,
            eventType: eventTypeMap[eventType].tiktok
        })

        if (window.gtag) {
            if (eventType === 'page_view') {
                window.gtag('event', 'conversion', {
                    send_to: `AW-16745532333/${googleAdsEventMap.page_view}`
                });
            }

            if (eventType === 'lead') {
                window.gtag('event', 'conversion', {
                    send_to: `AW-16745532333/${googleAdsEventMap.lead}`
                });
            }
        }

        const payload: { [key: string]: any } = {
            et: eventType,
            eid: EID,
            cid: CID,
            fu: window.location.href
        };

        if (UID) payload.uid = UID;

        if (userParams?.firstName) payload.ufn = userParams.firstName;
        if (userParams?.lastName) payload.uln = userParams.lastName;
        if (userParams?.email) payload.uem = userParams.email;
        if (userParams?.phone) payload.uph = userParams.phone;
        if (FBP) payload.fbp = FBP;

        let isRetried = false;

        const sendEvent = async () => {
            try {
                let response = await axios.post(
                    API_ENDPOINT,
                    payload,
                    { withCredentials: true }
                );
        
                if (response.data?.uid) {
                    Cookies.set('uid', response.data.uid, { expires: 365, secure: true, sameSite: 'Strict' });
                }
                return response;
            } catch (error:any) {
                if (error.response && (error.response.status === 401 || error.response.status === 403) && !isRetried) {
                    isRetried = true; // Set isRetried before retrying
                    await auth(); // Call getToken to refresh token
                    return sendEvent(); // Retry sendEvent after refreshing the token
                } else {
                    throw error;
                }
            }
        };
        return await sendEvent(); // Call sendEvent initially
    }
};

interface Props {
    eventType: 'page_view' | 'lead' | 'contact' | 'schedule';
    userParams?: UserParams;
}